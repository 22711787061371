import AccountsServices from "@services/APIs/Accounts";
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react";
import { useQuery } from "react-query";

const AgencyContext = createContext({});

export const useAgency = () => useContext(AgencyContext);

const AgencyProvider: FC<PropsWithChildren> = ({ children }) => {
  const slug = useMemo(() => {
    if (typeof window === "undefined") {
      return;
    }
    const currentUrl = window.location.hostname;
    const [subdomain, domain] = currentUrl.split(".");
    if (
      !subdomain ||
      [
        process.env.NEXT_PUBLIC_DEFAULT_SLUG,
        "app",
        "admin",
        "hub-analytics",
        "tgg-accounting",
        "localhost",
      ].includes(subdomain) ||
      domain.includes("vercel.app")
    ) {
      return;
    }

    window.location.replace(`https://${domain}/${subdomain}`);
  }, []);

  const { data, isSuccess, isLoading } = useQuery(
    [
      `/clients/slug/${slug}`,
      {
        slug: slug,
      },
    ],
    AccountsServices.getAgencyDetails,
    { refetchOnWindowFocus: false, enabled: !!slug }
  );

  const value = useMemo(
    () => ({
      agencyName: data?.name || "",
      logoUrl: data?.logoUrl || "",
      agencyId: data?.id || "",
      isLoading,
      isSuccess,
    }),
    [data, isLoading, isSuccess]
  );

  return (
    <AgencyContext.Provider value={value}>{children}</AgencyContext.Provider>
  );
};

export default AgencyProvider;
