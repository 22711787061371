import { tggClient } from "@services/AxiosConfig";
import {
  AddAgencyDto,
  UpdateAgencyDto,
} from "@tgg_accounting/hub-node-api/dist/models";
import { Agency, GetAllAgenciesQueryKey } from "@utils/types/agency";
import { AxiosResponse } from "axios";
import React from "react";
import { QueryFunctionContext } from "react-query";

class AgencyServices extends React.Component {
  addAgency = async (data: AddAgencyDto) => {
    if (data) {
      return await tggClient.post(`/agencies`, data);
    }
    console.warn("data not provided, AgencyServices.addAgency");
    return null;
  };

  getAllAgencies = async ({
    queryKey,
  }: QueryFunctionContext<GetAllAgenciesQueryKey>): Promise<
    AxiosResponse<any, any>
  > => {
    const [_key, params] = queryKey;

    return await tggClient.get(`/agencies`, {
      params: {
        ...params,
      },
    });
  };

  getAgencyById = async ({
    queryKey,
  }: {
    queryKey: [string, { [key: string]: any }];
  }) => {
    const [_key, { agencyId, include = [] }] = queryKey;
    if (agencyId) {
      const searchParams = new URLSearchParams();
      for (const param of include) {
        searchParams.append("include", param);
      }

      return tggClient.get(`/agencies/${agencyId}?${searchParams.toString()}`);
    }
    console.warn("agencyId not provided, AgencyServices.getAgencyById");
    return null;
  };

  // ACTIVATE AGENCY
  activateAgency = async (agencyId: string) => {
    if (agencyId) {
      return await tggClient.post(`/agencies/${agencyId}/activate`);
    }
    console.warn("agencyId not provided, AgenciesServices.activateAgency");
    return null;
  };

  // DEACTIVATE AGENCY
  deactivateAgency = async (agencyId: string) => {
    if (agencyId) {
      return await tggClient.post(`/agencies/${agencyId}/deactivate`);
    }
    console.warn("agencyId not provided, AgenciesServices.deactivateAgency");
    return null;
  };

  // UPDATE LOGO
  updateAgencyLogo = async (agencyId: string, file: Blob) => {
    if (agencyId && file) {
      let agencyLogo = new FormData();
      agencyLogo.append("file", file);
      const response = await tggClient.post<never, { logoUrl: string }>(
        `/agencies/${agencyId}/logo`,
        agencyLogo,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      await fetch(`/api/logo/${agencyId}`, {
        method: "POST",
        body: JSON.stringify({
          logoUrl: response.logoUrl,
        }),
      });
      return response;
    }
    console.warn(
      "agencyId or file not provided, AgencyServices.updateAgencyLogo"
    );
    return null;
  };

  // UPDATE AGENCY BY ID
  updateAgencyById = async (
    agencyId: string,
    data: UpdateAgencyDto
  ): Promise<Agency | null> => {
    if (agencyId) {
      return await tggClient.patch(`/agencies/${agencyId}`, data);
    }
    console.warn("agencyId not provided, AgenciesServices.updateAgencyById");
    return null;
  };
}

const instance = new AgencyServices({});

export default instance;
